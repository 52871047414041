import { computed, type ComputedRef } from 'vue';
import { useEmployeeStore } from '@/account/stores/employee';
import { type EmployeeDto, PaginationStep } from '@containex/portal-backend-dto';
import useGlobalToast from '@/composables/useGlobalToast';
import { getLogger } from '@/logger/logger';
import { httpClient } from '@/common/api/http-client';
import { companyEmployeesApi } from '@containex/portal-backend-api-client';

export interface EmployeeQuery {
    employees: ComputedRef<EmployeeDto[]>;
    totalEmployeeAmount: ComputedRef<number>;
}

export interface EmployeeAction {
    fetchEmployees(employeeAmount: number, step: PaginationStep): Promise<void>;
}

export function useEmployeeQuery(): EmployeeQuery {
    const store = useEmployeeStore();

    return {
        employees: computed(() => store.employees),
        totalEmployeeAmount: computed(() => store.totalEmployeeAmount),
    };
}

export function useEmployeeAction(): EmployeeAction {
    const store = useEmployeeStore();
    const { errorToastOptions, addToast } = useGlobalToast();
    const logger = getLogger('EmployeeAction');

    return {
        async fetchEmployees(employeeAmount: number, step: PaginationStep): Promise<void> {
            try {
                const employees = store.employees;
                let pageCursorId;

                if (step === PaginationStep.New || employees == null) {
                    pageCursorId = null;
                } else {
                    const filterEmployee =
                        step === PaginationStep.Next ? employees[employees.length - 1] : employees[0];
                    pageCursorId = filterEmployee?.id;
                }

                const response = await companyEmployeesApi.fetchEmployees(httpClient, {
                    employeeAmount: String(employeeAmount),
                    step,
                    pageCursorId,
                });

                if (response.data != null) {
                    store.employees = response.data.employees;
                    store.totalEmployeeAmount = response.data.totalEmployeeAmount;
                }
            } catch (error) {
                logger.warn(error, 'Fetching employees failed');

                store.employees = [];

                addToast(errorToastOptions);
            }
        },
    };
}
