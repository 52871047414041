import { defineStore } from 'pinia';
import type { EmployeeDto } from '@containex/portal-backend-dto';

interface EmployeeState {
    employees: EmployeeDto[];
    totalEmployeeAmount: number;
}

export const useEmployeeStore = defineStore('employee', {
    state: (): EmployeeState => ({
        employees: [],
        totalEmployeeAmount: 0,
    }),
});
